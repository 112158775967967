.responsiveCal {

    position: relative; padding-bottom: 75%; height: 0; overflow: hidden;

}

.responsiveCal iframe {

    position: absolute; top:0; left: 0; width: 100%; height: 100%;
    .view-cap, .view-container-border {
        background-color: #0a6ebd;
    }

}
