// Bootstrap brand color customization

/*     brand Colors              */

//$brand-primary:              $green-800 !default;
// $brand-primary:              #88ab3e !default;
// $brand-secondary:            #62abdf !default;

// $brand-primary:              #62abdf !default;
// $brand-secondary:            #88ab3e !default;
// $brand-tertiary:            #385f75 !default;

// Primary
$brand-primary: #385f75 !default;
$brand-primary-light: (
    1: #4a7389,
    2: #5c879d,
    3: #6e9bb1,
    4: #80afc5,
    5: #80afc5,
    6: #f8fbfc,
);

$brand-primary-dark: (
    1: #324f63,
    2: #2b404f,
    3: #24313b,
    4: #1d2227,
    5: #161313,
    6: #161313,
);

// Secondary
$brand-secondary: #88ab3e !default;

// Tertiary
$brand-tertiary: #62abdf !default;

$brand-info: $teal-400 !default;
$brand-success: $green !default;
$brand-warning: $orange-500 !default;
$brand-danger: $red-800 !default;
$brand-rose: $pink !default;
$brand-inverse: $black-color !default;

.brand-bg-gradient {
    background-image: url("/assets/img/bg-gradient-blue.jpg");
}
.brand-bg-image {
    background-image: url("/assets/img/downtownaerial.jpg");
}

.brand-primary {
    color: $brand-primary !important;
}
.brand-secondary {
    color: $brand-secondary !important;
}
.brand-tertiary {
    color: $brand-tertiary !important;
}

@for $i from 1 through 6 {
    .brand-primary-light-#{$i} {
        color: map-get($brand-primary-light, $i) !important;
    }
    .brand-primary-dark-#{$i} {
        color: map-get($brand-primary-dark, $i) !important;
    }
}
@for $i from 1 through 6 {
    .bgg-brand-primary-light-#{$i} {
        background-color: map-get($brand-primary-light, $i) !important;
    }
    .bgg-brand-primary-dark-#{$i} {
        background-color: map-get($brand-primary-dark, $i) !important;
    }
}
