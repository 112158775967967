.page-header{
    height: 100vh;
    background-position: center center;
    background-size: cover;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;

    .carousel .carousel-indicators{
        bottom: 60px;
    }

    > .container{
        color: $white-color;
    }

    .title{
        color: $white-color;
    }

    &.header-small{
        height: 45vh;
        min-height: 45vh;
    }

    &.header-tiny{
        height: 30vh;
        min-height: 30vh;
    }
    &.header-minimal{
        height: 25vh;
        min-height: 25vh;
    }

    &.header-medium{
        height: 55vh;
        min-height: 55vh;
    }

    &.header-adaptive{
        @media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {
            height: 40vh;
            min-height: 40vh;
        }
        @media only screen and (max-width: map-get($grid-breakpoints, 'md')) and (min-width: map_get($grid-breakpoints, 'sm')+1px) {
            height: 45vh;
            min-height: 45vh;
        }
        @media only screen and (min-width: map_get($grid-breakpoints, 'md')+1px) {
            height: 55vh;
            min-height: 55vh;
        }

    }

    .iframe-container{
        iframe{
            width: 100%;
            box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
                        0 4px 25px 0px rgba(0, 0, 0, 0.12),
                        0 8px 10px -5px rgba(0, 0, 0, 0.2);
        }
    }
}

.header-filter{
    position: relative;

    &:before,
    &:after{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
    }

    &::before{
    background: rgba(0,0,0,.5);
    }

    .container{
        z-index: 2;
        position: relative;
    }
}
.clear-filter::before {
  background: none;
}

.purple-filter:after{
  background: rgba(101, 47, 142, 0.64);
  background: linear-gradient(45deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
  background: -moz-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
  background: -webkit-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
}
